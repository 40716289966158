@import '@material-symbols/font-300';
@import '@material-symbols/font-600';
@import './styles/utils.scss';
@import './styles/semantic-ui.scss';
@import './styles/indexes.scss';
@import './styles/toastify.scss';

body {
  background-color: var(--ac-color-white-1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.description p {
  margin: 0;
}

input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: transparent;
}

.menu-wrapper {
  display: flex;
  flex-direction: column;

  &--logoLeft {
    flex-direction: row;

    .menu--header {
      text-align: left;
      flex: 1;
    }

    .ui.menu.menu--hover {
      width: auto;
      margin-top: auto;

      .right.menu {
        margin-left: 0;
      }
    }
  }
}

.menu--header {
  position: relative;
  text-align: center;

  img {
    max-height: 60px;
    margin: 4px 8px;
    vertical-align: middle;
  }

  .dropdown--languages {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .secondary {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__search--bar {
    width: 100%;

    &-shrink {
      width: 30rem;
    }
  }
}

.screen > .ui.grid {
  margin-top: 0;
}

.ui.grid {
  .column {
    .ui.segments > .ui.segment {
      margin-top: 0;
      margin-bottom: 0;
    }

    .ui.segment {
      margin: 2rem 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.ui.card > :first-child,
.ui.cards > .card > :first-child.content--rounded-image {
  border-radius: 0.28571429rem !important;
}

.ui.card > .content.borderless {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

h2.ui.header,
h3.ui.header {
  color: #2185d0;
}

.card--event {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  width: 100%;
  box-shadow: var(--ac-shadow-primary), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.image-with-shadow {
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--ac-shadow-secondary), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.ui.menu.menu--hover {
  width: 100%;

  .dropdown.item > .menu {
    display: none;
  }

  .dropdown.item:hover > .menu {
    display: block;
  }

  .dropdown.item > .menu,
  .ui.text.menu .dropdown.item > .menu {
    margin-top: 0;
  }

  .item > .ui.label {
    margin-top: -3px;
    margin-bottom: -3px;
  }
}

.details__container .ui.label, .description .ui.label {
  margin-left: 0;
  margin-right: .14285714em;
  margin-bottom: .14285714em;
}

#root > .ui.container {
  padding-bottom: 2rem;

  > .ui.header {
    color: white;
  }
}

.Toastify__toast-body {
  color: black;
}

.swal2-title {
  font-size: 18px !important;
}

.ui.container > .ui.grid {
  margin-top: 0;
}

@media (max-width: 1200px) {
  .menu > .item {
    .large.monitor {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .menu-container {
    padding-top: 0;
  }
  .menu--header {
    .dropdown--languages {
      display: none !important;
    }
    img {
      width: 100%;
      margin: 0px;
      max-height: max-content;
    }
  }

  .ui.grid {
    display: block;

    > .stretched.row {
      padding: 0em;
      display: block;

      > .column {
        margin: 8px 0em;
        width: 100% !important;
      }
    }

    > [class*='wide'].column {
      width: 100% !important;
    }

    .ui.cards {
      display: block !important;
      margin: 0px !important;
    }
  }

  .ui.cards {
    margin: 10px 0px;
    display: block !important;

    > .card {
      width: calc(100% - 2em) !important;
    }
  }

  .ui.form:not(.unstackable) .fields:not(.unstackable) > .field {
    margin-bottom: 1em;
  }
}

.card__read-more {
  float: right;
  margin-top: 8px;
}

.items--group {
  margin-bottom: 16px;

  .ui.cards {
    margin-bottom: 0;
  }
}

// Remove double-shadows
.ui.segment > .ui.message {
  background: none;
  box-shadow: none;
  padding: 0;
}

.column > .ui.segments.block--html {
  > .ui.segment {
    min-height: 100%;
  }
}

.ui.segments.block--video {
  .ui.segment:first-child {
    margin-bottom: 0;
  }

  .ui.segment:not(:first-child) {
    padding: 0;
    margin-top: 0;
  }
}

.tags > .ui.label {
  margin-left: 0;
  margin-bottom: 2px;
}

html,
body {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 100dvh;
  scroll-behavior: smooth;
}

body.disable-scroll {
  width: 100% !important;
  position: fixed !important;
  height: 100% !important;
  overflow: hidden !important;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#root > .ui.container {
  flex: 1 0 auto; // Grows but doesn't shrink. (to keep normal scroll handling)
  display: flex;
  flex-direction: column;
}

a.item:focus {
  outline: none;
}

.ui.list {
  > .item.item--table {
    display: table;
    width: 100%;
    table-layout: fixed;

    > .image-container {
      display: table-cell;
    }

    > .content {
      width: inherit;
      padding-left: 1em;
      display: table-cell !important;
    }
  }
}

.full-size-block {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  flex: 1 0 auto;
}

.clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.leaflet-container {
  width: 100%;
  background-color: #f4f4f4 !important;
  height: 85vh;
  z-index: 100;
}

.page.page--home.page--cms {
  .block--cms-files {
    .content--image > .ui.image {
      height: 10em;
    }
  }
}

.page--bottomTabs {
  min-height: 100vh;

  .page--cms-container {
    min-height: 100vh;
  }
}

.button--mobile-action {
  position: fixed;
  margin: 0;
  padding: 0;
  border: none;
  right: 11px;
  bottom: 11px;
  width: 44px;
  height: 44px;
  line-height: 44px;
  z-index: 100;
  border-radius: 50% !important;
  background-color: var(--ac-color-secondary);
  color: white;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  i.icon {
    margin-right: 0;
  }
}

// Bottom tabs take some space, move back up
.page--bottomTabs .button--mobile-action {
  bottom: 61px;
}
.page--bottomTabs .page--cms-container > .content {
  padding-bottom: 50px;
}

.rounded-modal {
  border-radius: 10px !important;
  overflow: hidden;
}

a {
  color: var(--ac-color-primary);
}

.ui.menu.menu--main:not(.secondary) .active.item {
  color: var(--ac-color-primary);
}

.ui.menu.menu--main.secondary:not(.pointing) .active.item {
  background: var(--ac-color-primary);
  color: white;
}

.ui.primary.button,
.ui.primary.buttons .button {
  background-color: var(--ac-color-primary);
}

.ui.primary.basic.button {
  color: var(--ac-color-primary) !important;
  box-shadow: 0 0 0 1px var(--ac-color-primary) inset !important;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover,
.ui.primary.button:focus {
  background-color: var(--ac-color-primary-70);
}

.ui.menu.menu--main.secondary.pointing .active.item,
.Tab.active {
  border-color: var(--ac-color-primary);
  color: var(--ac-color-primary);
}

.BookAppointmentBlock--slots__slot.ui.button {
  border-color: var(--ac-color-primary);
  background-color: var(--ac-color-primary-20);
}

.BookAppointmentBlock--slots__slot.ui.button.active,
.BookAppointmentBlock--slots__slot.ui.button:hover {
  background-color: var(--ac-color-primary);
}

.toastify-progress-bar {
  background: var(--ac-color-primary) !important;
  &.Toastify__progress-bar {
    &--success {
      background: #07bc0c !important;
    }
  }
}

h2.ui.header,
h3.ui.header {
  color: var(--ac-color-primary);
}

.workshop--category,
.workshop--details__content--category {
  color: var(--ac-color-primary);
}

.ui.tiny.primary.button {
  background-color: var(--ac-color-primary);
}

.ui.button.back-button,
.ui.button.BackButton {
  color: var(--ac-color-primary);
}

.BackButton.BackButton--variant-square-icon.BackButton--inverted .icon {
  color: var(--ac-color-primary);
}

.footer .footer--text {
  background-color: var(--ac-color-primary);
}

.card__read-more,
.block--cms-files .ui.card > .content > .header {
  color: var(--ac-color-primary);
}

.inject-primary-color {
  color: var(--ac-color-primary) !important;
}
button.FloatingChat__Bar {
  background-color: var(--ac-color-primary);
}

.item--workshop > .icon.circle,
.item--workshop > .content > .ui.header {
  color: var(--ac-color-primary) !important;
}
.ui.card.card--video > .content--custom-thumbnail {
  color: var(--ac-color-primary);
}

.workshop--commentsBlock .comment--input.ui.input {
  border-bottom-color: var(--ac-color-primary);
}

.countdown .clock,
.field--type-tag {
  background-color: var(--ac-color-primary);
  color: #fff;
}
.countdown.inverted .clock {
  background-color: #fff;
  color: var(--ac-color-primary);
}

.countdown-wrapper .countdown-item {
  color: var(--ac-color-primary);
}
.countdown-wrapper.inverted .countdown-item {
  color: #fff;
}

.countdown-wrapper .countdown-svg path {
  stroke: var(--ac-color-primary);
}
.countdown-wrapper.inverted .countdown-svg path {
  stroke: #fff;
}

.Step__step.active {
  background-color: var(--ac-color-primary);
}

.exhibitor-group__cards__card .ui.button.see-more {
  background-color: var(--ac-color-primary) !important;
}

.list-details__items__item.is-active {
  background-color: var(--ac-color-primary);
  color: white;
}

.block--ItemGrid .ui.items > .item > .content > .header {
  color: var(--ac-color-primary);
}

.UserInfo__avatar--placeholder:not(.UserInfo__avatar--inverted) {
  background-color: var(--ac-color-primary);
}

.UserInfo__avatar--placeholder.UserInfo__avatar--inverted {
  color: var(--ac-color-primary);
}

.sidebar--menu__header .bars.icon,
.sidebar--menu__container--header .icon.times,
.sidebar--menu__header .power.off.icon {
  color: var(--ac-color-primary);
}

.WorkshopModal__content .footer .previous .ui.button,
.WorkshopModal__content .footer .next .ui.button {
  background-color: var(--ac-color-primary);
}

.WorkshopModal__content .footer .previous,
.WorkshopModal__content .footer .next {
  color: var(--ac-color-primary);
}

.WorkshopModal__content .footer .previous.disabled,
.WorkshopModal__content .footer .next.disabled {
  color: var(--ac-color-primary-50);
}

.SpeakerModal .SpeakerModal.SpeakerModal--header {
  background-color: var(--ac-color-primary);
  color: #fff;
}

.item--languages-horizontal .lang a {
  color: #fff;
}
.item--languages-horizontal .lang.is-active a,
.item--languages-horizontal .lang a:hover {
  color: var(--ac-color-primary);
}

.go-back {
  color: var(--ac-color-primary);
}

.ui.modal.UserPreferencesModalBlock .ui.card.preference .content .ui.button.follow.active {
  color: var(--ac-color-primary) !important;
}

.DataItems__group--variant-accordion .Collapsible__trigger {
  background-color: var(--ac-color-primary);
}

.FaqBlock .FaqBlock__question .title,
.FaqBlock .FaqBlock__question i {
  color: var(--ac-color-primary);
}

.Toastify__toast-container.badge-notification .Toastify__toast {
  background: var(--ac-color-primary);
}

.WorkshopIframeRegistration__registered .ui.message {
  background-color: var(--ac-color-primary-20);
}

.NotificationsPopup__List__see-all-button {
  background: var(--ac-color-primary) !important;
}

.MessagesPopup__List__Chat i.arrow {
  color: var(--ac-color-primary);
}

.menu--filter .item.reset-all {
  color: var(--ac-color-primary);
}

.favorite.exhibitor .icon.star {
  color: var(--ac-color-primary) !important;
}

.Leaderboard__Menu.ui.menu .item.active {
  background-color: var(--ac-color-primary);
}

.RankingList.ui.list .item .thumbnail .you {
  background-color: var(--ac-color-primary);
}

.material-symbols-outlined {
  font-weight: 600;
}
