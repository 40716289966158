.ActionsTab {
  flex: 1;
  height: 0px;
  overflow-y: auto;
  &__Action {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-bottom: 0.5em;
    background-color: var(--ac-color-primary-10);
    padding: 0.8em 1em;
    border-radius: 10px;

    &--key {
      &-disconnect {
        background-color: #ffe8ec;

        .label {
          color: var(--ac-color-error);
        }

        i {
          color: var(--ac-color-error) !important;
          background-color: #fcd2d2 !important;
        }

        .material-symbols-outlined {
          color: var(--ac-color-error) !important;
          background-color: #fcd2d2 !important;
        }
      }
    }

    i {
      font-size: 1em;
      height: 24px;
      width: 24px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      background-color: var(--ac-color-primary-70);
      border-radius: 4px;
      color: white;
    }
    .material-symbols-outlined {
      margin-right: 8px;
      padding: 4px;
      background-color: var(--ac-color-primary-70);
      border-radius: 4px;
      color: white;
    }

    .label {
      flex: 1;
      font-weight: 500;
      line-height: 1;
    }

    .ui.toggle.checkbox input:checked ~ .box:before,
    .ui.toggle.checkbox input:checked ~ label:before {
      background-color: var(--ac-color-primary) !important;
    }
  }
}

.Action {
  & .bottom-panel {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2em;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  &.rounded-modal {
    padding: 1em;
    text-align: center;
    overflow: initial;
  }

  .slider-control-bottomcenter ul {
    top: 30px !important;
  }

  .slider-control-centerleft i.icon,
  .slider-control-centerright i.icon {
    font-size: 1.5em !important;
  }
}
.QRcodeAction {
  &__text {
    margin-top: 1em;
    text-align: center;

    h3.header {
      margin-bottom: 1em;
    }
  }

  &__qrcode {
    padding-top: 2em;
    text-align: center;
  }

  &__badge {
    display: flex;
    margin: auto;

    &.ui.button {
      margin: 1em auto;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: max-content;
    }

    .icon {
      margin-right: 1em;
      margin-top: -8px;
    }
  }
  &.rounded-modal {
    padding: 1em;
    text-align: center;
    overflow: initial;
  }
}

.FlightTicketsAction {
  &__text {
    margin-bottom: 2em;
    text-align: center;

    .material-symbols-outlined.icon {
      color: var(--ac-color-primary);
    }
    h3.header {
      margin-top: 0px;
      margin-bottom: 1em;
    }
  }
}
